import { useContext } from 'react';

import { CompassBlob, Context } from './';

export const useCompassContext = () => {
  const context = useContext<CompassBlob | undefined>(Context);

  if (!context) {
    throw Error(
      `Please ensure that you have wrapped your application in a 'CompassContextProvider'
      and that the value passed in is correct.`,
    );
  }

  return context;
};
