import * as React from 'react';

import { ExtComponent } from '../ExtComponent';
import { ExtComponentExposedProps } from '../types';

export interface ChronicleEntryEditorBaseProps extends ExtComponentExposedProps {
  assetPath: string;
  idPhotosPath: string;
  userId: number;
  baseRole: number;
  chronicleAdminEdit: boolean;
  chronicleAdminView: boolean;
  chroniclePowerEdit: boolean;
  chroniclePowerView: boolean;
  chronicleUserEdit: boolean;
  chronicleUserView: boolean;
  sickBayAdmin: boolean;
  chronicleParentViewEnabled: boolean;
  chronicleEditEnabled: boolean;
  chronicleTriggersEnabled: boolean;
  approvalGroupsEnabled: boolean;
}

export interface NewChronicleEntryProps extends ChronicleEntryEditorBaseProps {
  newChronicleEntry: NewChronicleEntry;
}

export interface NewChronicleEntry {
  targetUserIds?: number[];
  parentEntryId?: number;
  forParent?: boolean;
  fromProfile?: boolean;
}

export interface OpenChronicleEntryProps extends ChronicleEntryEditorBaseProps {
  openChronicleEntry: OpenChronicleEntry;
}

export interface OpenChronicleEntry {
  recordId: number;
  parentRecordId?: number;
}

export interface ChronicleEntryEditorEvents {

  onEntryCreated?: (id: number) => void;

  onEntryEdited?: (id: number) => void;

  /**
   * Save and Close / Cancel is clicked
   */
  onClose?: () => void;
}

export type ChronicleEntryEditorProps = (NewChronicleEntryProps | OpenChronicleEntryProps) & ChronicleEntryEditorEvents;

/**
 * An instance of the Chronicle Entry Editor widget
 * @param {object} props Entry editor options
 * @returns {JSX.Element} Component
 */
export const ChronicleEntryEditor = ({
  onError,
  onException,
  onEntryCreated,
  onEntryEdited,
  onClose,
  mask,
  className,
  ...props
}: ChronicleEntryEditorProps) => (
  <ExtComponent
    title="Chronicle Entry Editor"
    js={['CTemplate', 'CKEditor', 'CKEditorExtPlugin', 'ChronicleV2Module']}
    jsPreExistingMinified="CKEditor"
    css="ChronicleV2"
    widget="Compass.widgets.ChronicleEntryEmbeddedEditorWidget"
    listeners={{ onError, onException, onEntryCreated, onEntryEdited, onClose }}
    props={props}
    mask={mask}
    className={className}
  />
);
