import {
  ExtComponentProps,
  ExtEventListeners,
  ExtIframeMessage,
  ExtIframeMessageType,
  ExtMvcProps,
  ExtWidgetProps,
} from './types';

// we can depend on window.location.protocol and window.location.host to be static
const iframeUri = `${window.location.protocol}//${window.location.host}/ExtComponent.aspx?`;

export const handleIFrameMessage = (
  iframe: HTMLIFrameElement,
  event: MessageEvent,
  listeners: ExtEventListeners,
  onReady: () => void,
): void => {

  if (event.origin !== window.origin) return; // prevent naughty people from trying naughty things
  if (event.source !== iframe.contentWindow) return; // not from this iframe

  const data = JSON.parse(event.data) as ExtIframeMessage;
  switch (data.type) {
    case ExtIframeMessageType.Ready:
      onReady();
      break;
    case ExtIframeMessageType.Height:
      iframe.height = data.height;
      break;
    case ExtIframeMessageType.Event:
      if (listeners) {
        const listener = listeners[data.event];
        if (listener) {
          if (Array.isArray(data.payload)) {
            listener(...data.payload);
          } else {
            listener(data.payload);
          }
        }
      }
      break;
    case ExtIframeMessageType.Navigate:
      // bye bye
      window.location.href = event.data.uri;
      break;
    default: throw TypeError(`Unsupported message ${JSON.stringify(data)}`);
  }
};

export const bindListeners = (listeners: ExtEventListeners): ExtEventListeners => {
  const map: ExtEventListeners = {};
  for (const key of Object.keys(listeners)) {
    map[formatListenerKey(key)] = listeners[key];
  }
  return map;
};

const formatListenerKey = (key: string) => {
  if (!key.startsWith('on')) throw new TypeError('By convention, an event listener name must start with "on"');
  return key.substring(2).toLowerCase();
};

const listenerKeys = (listeners: ExtEventListeners) => Object.keys(listeners).map(formatListenerKey);

/**
 * Determines the source URL of the Ext component iframe wrapper
 * @param {object} props MVC or Widget properties
 * @returns {object} the Widget
 */
export function src<TWidget, TView, TController>(
  props: ExtWidgetProps<TWidget> | ExtMvcProps<TView, TController>,
): string {

  const param = [];

  // construct params
  if (isWidget(props)) {
    param.push(`widget=${props.widget}`);
    if (props.props) param.push(encodeArgs('widgetArgs', props.props));
  } else {
    param.push(`controller=${props.controller}`);
    param.push(`view=${props.view}`);
    if (props.viewProps) param.push(encodeArgs('viewArgs', props.viewProps));
    if (props.controllerProps) param.push(encodeArgs('controllerArgs', props.controllerProps));
  }

  // required
  param.push(encodeArray('js', props.js));

  // optional
  if (props.jsMinifyDontBundle) param.push(encodeArray('jsMinifyDontBundle', props.jsMinifyDontBundle));
  if (props.jsPreExistingMinified) param.push(encodeArray('jsPreExistingMinified', props.jsPreExistingMinified));
  if (props.css) param.push(encodeArray('css', props.css));
  if (props.cssMinifyDontBundle) param.push(encodeArray('cssMinifyDontBundle', props.cssMinifyDontBundle));
  if (props.cssPreExistingMinified) param.push(encodeArray('cssPreExistingMinified', props.cssPreExistingMinified));
  if (props.listeners) param.push(encodeArray('listeners', listenerKeys(props.listeners)));

  return iframeUri + param.join('&');
}

const encodeArgs = (name: string, props: any) => `${name}=${encodeURIComponent(JSON.stringify(props))}`;
const encodeArray = (name: string, array: string | string[]) =>
  `${name}=${encodeURIComponent(typeof array === 'string' ? array : array.join(','))}`;

function isWidget<T>(props: ExtComponentProps): props is ExtWidgetProps<T> {
  return 'widget' in props;
}
