import { useEffect, useState } from 'react';

import { CompassServiceLoadingResponse, fetchCompassService } from '.';

export const useCompassService = <T>(url: string, body?: any): [CompassServiceLoadingResponse<T>, () => void] => {
  const [resultState, setResultState] = useState<CompassServiceLoadingResponse<T>>({ state: 'loading' });
  // This is so we can easily refetch the request. incrementing the counter will re-run "useEffect".
  const [refetchCounter, setRefetchCounter] = useState(0);

  useEffect(() => {
    async function fetch() {
      try {
        const result = await fetchCompassService<T>(url, body);

        setResultState({ ...result });
      } catch (err) {
        setResultState({ state: 'error', message: err });
      }
    }
    fetch();
  }, [url, body, refetchCounter]);

  const refetch = () => {
    setRefetchCounter(refetchCounter + 1);
    setResultState({ state: 'loading' });
  };

  return [resultState, refetch];
};
