import { CompassServiceResponse } from './compass-service-response';

interface WcfFetchError {
  ExceptionDetail: {
    Message: string;
    StackTrace: string;
  };
}

export interface WcfFetchSuccess<T> {
  d: T;
}

export type WcfFetchResponse<T> = WcfFetchError | WcfFetchSuccess<T>;

interface Headers {
  [k: string]: string;
}

function getHeaders(): Headers | undefined {
  if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
    return { 'cache-control': 'no-cache' };
  }

  return undefined;
}

function isSuccess<T>(response: WcfFetchResponse<T>): response is WcfFetchSuccess<T> {
  return (response as WcfFetchSuccess<T>).d !== undefined;
}

export async function fetchCompassService<T>(url: string, body?: any): Promise<CompassServiceResponse<T>> {
  try {
    const result = await fetch(url, { method: 'POST', headers: getHeaders(), body: body && JSON.stringify(body) });

    if (result.status === 500) {
      return { state: 'error', message: 'Sorry, something went wrong.' };
    }

    const data = (await result.json()) as WcfFetchResponse<T>;

    if (isSuccess(data)) {
      return { state: 'success', data: data.d };
    }

    return { state: 'error', message: data.ExceptionDetail.Message };
  } catch (e) {
    return { state: 'error', message: `Error: ${e}` };
  }
}
