import { CompassBlob } from '.';
import { useCompassService } from '../compass-service';

export const useCompassBlob = () => {
  const [result] = useCompassService('/Services/ReferenceData.svc/GetSchoolConfig');

  if (result.state === 'success') {
    // We are doing this because refactoring the Compass side method will be way too much effort.
    // I attempted this. It wasn't fun. There is a potential security risk here however, but this
    // is only a concern if school attributes have some malicious code in them.
    // tslint:disable-next-line: no-eval
    return eval(`${result.data};Compass;`) as CompassBlob;
  }

  return undefined;
};
