import * as React from "react";

import { CompassContext, withCompassContext } from "./compass-blob";
import {
  CalendarDayWidget,
  ChronicleEntryEditor,
  NewChronicleEntry,
  OpenChronicleEntry
} from "./ext-components";

const { useState } = React;

const ChronicleEntries = ({ compass }: CompassContext) => {
  const [entries, setEntries] = useState<number[]>([]);
  const [editEntry, setEditEntry] = useState<OpenChronicleEntry | null>(null);
  const [newEntry, setNewEntry] = useState<NewChronicleEntry | null>(null);

  const onEditEntry = (recordId: number) => () => {
    setNewEntry(null);
    setEditEntry({ recordId });
  };
  const onEntryEdited = () => setEditEntry(null);
  const onEditEntryClose = () => setEditEntry(null);
  const onNewEntry = () => {
    setNewEntry({});
    setEditEntry(null);
  };
  const onNewEntryClose = () => setNewEntry(null);
  const onEntryCreated = (id: number) => {
    setEntries([...entries, id]);
  };

  return (
    <div>
      <ul>
        {entries.map((id, i) => (
          <li key={`c${i}`} onClick={onEditEntry(id)}>
            Entry {id}
          </li>
        ))}
      </ul>

      <button onClick={onNewEntry}>New Entry</button>

      {newEntry && (
        <ChronicleEntryEditor
          assetPath={compass.assetPath}
          idPhotosPath={compass.idPhotosPath}
          baseRole={compass.organisationUserBaseRole}
          userId={compass.organisationUserId}
          newChronicleEntry={newEntry}
          onClose={onNewEntryClose}
          onEntryCreated={onEntryCreated}
          approvalGroupsEnabled
          chronicleAdminEdit
          chronicleAdminView
          chronicleEditEnabled
          chronicleParentViewEnabled
          chroniclePowerEdit
          chroniclePowerView
          chronicleTriggersEnabled
          chronicleUserEdit
          chronicleUserView
          sickBayAdmin
        />
      )}

      {editEntry && (
        <ChronicleEntryEditor
          assetPath={compass.assetPath}
          idPhotosPath={compass.idPhotosPath}
          baseRole={compass.organisationUserBaseRole}
          userId={compass.organisationUserId}
          openChronicleEntry={editEntry}
          onClose={onEditEntryClose}
          onEntryEdited={onEntryEdited}
          approvalGroupsEnabled
          chronicleAdminEdit
          chronicleAdminView
          chronicleEditEnabled
          chronicleParentViewEnabled
          chroniclePowerEdit
          chroniclePowerView
          chronicleTriggersEnabled
          chronicleUserEdit
          chronicleUserView
          sickBayAdmin
        />
      )}
    </div>
  );
};

const KitchenSinkComponent = ({ compass }: CompassContext) => (
  <article style={{ paddingLeft: "4rem" }}>
    <h2>Kitchen Sink</h2>
    <div style={{ height: "100%", width: "100%", display: "flex" }}>
      <div>
        <h3>CalendarDayWidget</h3>
        <CalendarDayWidget
          targetUserId={compass.organisationUserId}
          viewingUserId={compass.organisationUserId}
          viewingBaseRole={compass.organisationUserBaseRole}
          optInEvents_Enabled
          border={1}
          margin="0 0 15 0"
          mode={1}
          height={700}
        />
      </div>
      <div style={{ width: "700px", marginLeft: "1rem" }}>
        <h3>Chronicle Entries</h3>
        <ChronicleEntries compass={compass} />
      </div>
    </div>
  </article>
);

export const KitchenSink = withCompassContext(KitchenSinkComponent);
