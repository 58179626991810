import * as React from 'react';

import { ExtComponent } from '../ExtComponent';
import { ExtComponentExposedProps } from '../types';

export interface CalendarDayWidgetProps extends ExtComponentExposedProps {
  targetUserId: number;
  viewingUserId: number;
  viewingBaseRole: number;
  optInEvents_Enabled: boolean;
  border: number;
  margin: string;
  mode: number;
  height: number;
}

export const CalendarDayWidget = ({ mask, className, onError, onException, ...props }: CalendarDayWidgetProps) => (
  <ExtComponent
    title="Calendar"
    js="CalendarAndExtensions"
    jsPreExistingMinified="CalendarAndExtensions"
    css={['Extensible', 'ExtensibleRecurrence', 'Calendar']}
    widget="Compass.widgets.CalendarDayWidget"
    mask={mask}
    className={className}
    listeners={{ onError, onException }}
    props={props}
  />
);
