import * as React from 'react';

import { CompassBlob } from './compass-blob';

export const Context = React.createContext<CompassBlob | undefined>(undefined);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

export interface CompassContext {
  compass: CompassBlob;
}
