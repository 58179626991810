import * as React from 'react';

import warning from './img/warning.png';

export const ConstructionPage = () => (
  <div id="container" className="hero">
    <img alt="Under Construction" src={warning} width="140" height="140" />
    <div className="info">
      <h1 className="title">Under Construction</h1>
      <h2 className="subtitle"> Please check back soon.</h2>
    </div>
  </div>
);
