import * as React from 'react';

import { ExtMvcProps, ExtWidgetProps } from './types';
import { bindListeners, handleIFrameMessage, src } from './util';

const { useEffect, useRef, useMemo } = React;

/**
 * An Ext.js Widget or MVC component
 * @param {object} props MVC or Widget properties
 * @returns {object} the Ext component
 */
// tslint:disable-next-line: function-name
export function ExtComponent<
  TWidget,
  TView,
  TController
>(props: ExtWidgetProps<TWidget> | ExtMvcProps<TView, TController>) {

  const timeoutRef = useRef<number | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const maskRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);

  const onReady = () => {
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    if (errorRef.current) errorRef.current.style.display = 'none';
    if (maskRef.current) maskRef.current.style.display = 'none';
    if (iframeRef.current) iframeRef.current.style.display = 'block';
  };

  const onLoadStart = () => {
    if (maskRef.current) maskRef.current.style.display = 'block';
    if (errorRef.current) errorRef.current.style.display = 'none';
  };

  // once the iframe contents have loaded, we expect to receive a ready message
  const onLoad = () => timeoutRef.current = window.setTimeout(() => {
    // ... otherwise, something has gone wrong. Show the error element.
    if (maskRef.current && maskRef.current.style.display !== 'none') {
      if (errorRef.current) errorRef.current.style.display = 'block';
      if (maskRef.current) maskRef.current.style.display = 'none';
      if (iframeRef.current) iframeRef.current.style.display = 'none';
    }
  }, 1000); // within 1 second of loading

  // handle messages from iframe
  useEffect(() => {

    // bind custom event handlers
    const listeners = props.listeners ? bindListeners(props.listeners) : {};

    const messageHandler = (event: MessageEvent) => {
      if (iframeRef.current === null) return;
      handleIFrameMessage(iframeRef.current, event, listeners, onReady);
    };

    window.addEventListener('message', messageHandler);

    // detach event listener on component unmount
    return () => window.removeEventListener('message', messageHandler);
  }, [props.listeners]); // only reattach on custom event listeners changed

  const uri = useMemo(() => src(props), [props]); // memoize iframe uri for performance

  const mask = props.mask || <>Loading...</>;

  return (
    <div className={props.className} style={{ position: 'relative' }}>
      <div ref={errorRef} style={{ display: 'none' }}>We're sorry, something has gone wrong.</div>
      <div ref={maskRef} style={{ width: '100%', height: '100%' backgroundColor: '#fff', zIndex: 1, position: 'absolute', top: 0, left: 0 }}>
        {mask}
      </div>
      <iframe
        ref={iframeRef}
        title={props.title}
        src={uri}
        onLoad={onLoad}
        onLoadStart={onLoadStart}
        width="100%"
        frameBorder="0"
        scrolling="no"
      />
    </div>
  );
}
