import * as React from 'react';

import { CompassContext, useCompassContext } from '.';

export function withCompassContext<P extends CompassContext>(Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof CompassContext>) => {
    const compass = useCompassContext();

    return <Component {...props as P} compass={compass} />;
  };
}
