import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import { Provider, useCompassBlob } from './compass-blob';
import { useCompassService } from './compass-service';
import { ConstructionPage } from './construction-page';
import { KitchenSink } from './kitchen-sink';
import { NotFound } from './not-found';

const App = () => {
  const [isLoggedInResult] = useCompassService<boolean>('/Services/Admin.svc/IsLoggedIn');
  const compassBlob = useCompassBlob();

  if (
    isLoggedInResult.state === 'error' ||
    (isLoggedInResult.state === 'success' && !isLoggedInResult.data) ||
    (compassBlob && !compassBlob.attributes.UnifiedFeedEnabled)
  ) {
    window.location.replace('/');
  }

  if (!compassBlob || isLoggedInResult.state === 'loading') {
    return <div>Loading...</div>;
  }

  return (
    <Provider value={compassBlob}>
      <BrowserRouter basename="/app">
        <Route>
          <header className="header">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="kitchen-sink">Kitchen Sink</Link>
              </li>
            </ul>
          </header>
          <Switch>
            <Route path="/" exact component={ConstructionPage} />
            <Route path="/kitchen-sink" component={KitchenSink} />
            <Route component={NotFound} />
          </Switch>
        </Route>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('main'));
