/**
 * Base properties for Ext Components
 */
export interface ExtComponentProps extends ExtComponentElementProps {

  /**
   * A title is required for iframe accessibility.
   * @see {link https://dequeuniversity.com/tips/provide-iframe-titles} for more details.
   */
  title: string;

  /**
   * JavaScript manifest as defined in CompassJsManifest
   */
  js: string | string[];

  /**
   * Minify and don't bundle the defined assets
   */
  jsMinifyDontBundle?: string | string[];

  /**
   * Defined assets are pre-existing and minified
   */
  jsPreExistingMinified?: string | string[];

  /**
   * CSS manifest as defined in CompassCssManifest
   */
  css?: string | string[];

  /**
   * Minify and don't bundle the defined assets
   */
  cssMinifyDontBundle?: string | string[];

  /**
   * Define assets are pre-existing and minified
   */
  cssPreExistingMinified?: string | string[];

  /**
   * Listeners for custom events from Ext components
   */
  listeners?: ExtEventListeners;
}

/**
 * Visual properties that can be exposed to derived components
 */
export interface ExtComponentElementProps {

  /**
   * Custom loading mask, defaults to <div>Loading....</div>
   */
  mask?: JSX.Element;

  /**
   * CSS class name
   */
  className?: string;
}

/**
 * Properties that should be exposed to derived components
 */
export type ExtComponentExposedProps = CompassBaseEvents & ExtComponentElementProps;

/**
 * A map of event listeners
 */
export interface ExtEventListeners {

  /**
   * A collection of event listeners, since we don't know the type of the
   * event until it is passed on to the destination component, the payload
   * type here is `any`
   */
  [event: string]: ((...payload: any[]) => void) | undefined;
}

export interface CompassErrorPayload {

  /**
   * HTML String
   */
  error: string;
  status: string;

  /**
   * Detail string, potentially parseable as JSON object
   */
  detail: string;
  detailType?: string;
}

export interface CompassExceptionEventPayload {
  id: string;
  details?: string;
  errorMessage: string;
  hash?: string;
}

export type CompassErrorEventHandler = (payload: CompassErrorPayload) => void;
export type CompassExceptionEventHandler = (payload: CompassExceptionEventPayload) => void;

/**
 * Global Compass events, common to all components
 */
export interface CompassBaseEvents {
  onError?: CompassErrorEventHandler;
  onException?: CompassExceptionEventHandler;
}

/**
 * Properties for Ext Widgets
 */
export interface ExtWidgetProps<T> extends ExtComponentProps {

  /**
   * Widget properties
   */
  props?: T;

  /**
   * Name of the Ext.js Widget component
   */
  widget: string;
}

/**
 * Properties for Ext MVC
 */
export interface ExtMvcProps<TViewProps, TControllerProps> extends ExtComponentProps {

  /**
   * Name of the Ext.js MVC View
   */
  view: string;

  /**
   * Name of the Ext.js MVC Controller
   */
  controller: string;

  /**
   * Filter controller props
   */
  viewProps?: TViewProps;

  /**
   * Filter view props
   */
  controllerProps?: TControllerProps;
}

/**
 * Type of message received from iframe
 */
export enum ExtIframeMessageType {

  /**
   * Component has rendered
   */
  Ready = 0,

  /**
   * Component reports a new height
   */
  Height = 1,

  /**
   * Component raised an event via Compass.iframeParentEvent
   */
  Event = 2,

  /**
   * A link is being opened
   */
  Navigate = 3,
}

export interface ExtIframeMessageBase {
  type: ExtIframeMessageType;
}

export interface ExtReadyMessage {
  type: ExtIframeMessageType.Ready;
}

export interface ExtHeightMessage extends ExtIframeMessageBase {
  type: ExtIframeMessageType.Height;
  height: string;
}

export interface ExtIframeEventMessage extends ExtIframeMessageBase {

  type: ExtIframeMessageType.Event;

  /**
   * Event name in lowercase convention
   */
  event: string;

  /**
   * Event payload
   */
  payload?: any;
}

export interface ExtIframeNavigateMessage extends ExtIframeMessageBase {
  type: ExtIframeMessageType.Navigate;
  uri: string;
}

export type ExtIframeMessage = ExtReadyMessage | ExtHeightMessage | ExtIframeEventMessage | ExtIframeNavigateMessage;
